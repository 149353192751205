import React from 'react'
import Header from './Header'
import Menu from './Menu'
import bg from './hall.jpg'

import './home.css'

export default () => (
  <main className='info'>
    <Header />
    <Menu />
    <div>
      <img src={bg} className='bg' />
      <div className='content'>
        <h2 className='content-title'>Om svar anhålles</h2>
        <p>
          Vänligen O.S.A innan den 3:e juli genom att <a href='https://docs.google.com/forms/d/e/1FAIpQLSdOLKg4QFdUymgaCPOWWw1ZkM67_CIzN61vc2i4CKpHkrTDNQ/viewform?usp=sf_link'>använda formuläret här!</a>
        </p>
        <p>
          Glöm inte att ange eventuell specialkost eller andra preferenser, exempelvis egen transport under dagen.
        </p>
      </div>
    </div>
  </main>
)
