import React from 'react'
import Header from './Header'
import Menu from './Menu'
import bg from './hall.jpg'
import operan from './operan.png'

import './home.css'

export default () => (
  <main className='info info-high'>
    <Header />
    <Menu />
    <div>
      <img src={bg} className='bg' />
      <div className='content'>
        <h2 className='content-title'>Bra att veta</h2>
        <br />
        <h3 className='content-sub-title'>Klädsel</h3>
        <p>Klädsel för dagen är mörk kostym.</p>
        <p>Brudföljet kommer dock bära frack, och alla gäster är varmt välkomna att följa denna klädsel om man så önskar.</p>
        <br />
        <h3 className='content-sub-title'>Barn</h3>
        <p>
          Barn är underbara, men det vore bäst om de stannar hemma denna dag.
        </p>
        <br />
        <h3 className='content-sub-title'>Transport</h3>
        <p>
          Transport till Ljusterö Kyrka sker i två förbokade bussar. Dessa kommer att avgå 12:00 från City Terminalen, en exakt gate kommer att stå på informationstavlorna och bussresan tar ca 1,5 timme. Information om upphämtningsplats kommer 3 veckor innan bröllopet.
        </p>
        <p>
          Efter vigseln väntar samma bussar utanför kyrkan och transporterar samtliga gäster från kyrkan till bryggan och den gamla Waxholmsbåten Oden Gamle.
        </p>
        <p>
          Båtresan till Strömkajen tar ca 2 timmar och på båten serveras förfriskningar och canapéer.
        </p>
        <br />
        <h3 className='content-sub-title'>Hotell</h3>
        <p>
          För långväga gäster så ger vi gärna råd om hotell eller övernattningsmöjligheter.
        </p>
        <p>
          På nedan karta kan Ni se vart i Stockholm festlokalen ligger.
            <img src={operan} className='operan' />
        </p>
        <br />
        <h3 className='content-sub-title'>Bröllopspresenter</h3>
        <p>
          Kanske inte det mest fantasifulla, men som bröllopspresent mottager vi gärna en liten gåva till vår bröllopsresa. Önskar ni bidra till detta så kontakta gärna våra toastmasters som håller i insamlingen.
        </p>
        <p>
          <a href='mailto:toastmaster@stime.se'>toastmaster@stime.se</a>
        </p>
      </div>
    </div>
  </main>
)
