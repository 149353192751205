import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/Home'
import Info from './components/Info'
import Osa from './components/Osa'
import Bra from './components/Bra'
import Anmal from './components/Anmal'

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/brollop" component={Info} />
      <Route path="/osa" component={Osa} />
      <Route path="/bra-att-veta" component={Bra} />
      <Route path="/anmal-tal" component={Anmal} />
    </Router>
  );
}

export default App;
