import React from "react";
import { Link } from "react-router-dom";

import './menu.css'

export default () => {
  return (
    <nav className='menu'>
      <ul>
        <li className='menu-item'>
          <Link to="/brollop">Bröllopet</Link>
        </li>
        <li className='menu-item'>
          <Link to="/osa">OSA</Link>
        </li>
        <li className='menu-item countdown'>
          <Link to="/">
            <div>
            <p>
              augusti
              <span className='huge-text'>3</span> 
              2019
            </p>
            </div>
          </Link>
        </li>
        <li className='menu-item'>
          <Link to="/bra-att-veta">Bra att veta</Link>
        </li>

        <li className='menu-item'>
          <Link to="/anmal-tal">Anmäl tal</Link>
        </li>
        {/* <li>
          <Link to="/topics">Kyrkan</Link>
        </li> */}
      </ul>
    </nav>
  );
}