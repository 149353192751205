import React from 'react'

import './header.css'

export default () => {
  return (
    <header className='header'>
      <h2>Välkomna till</h2>
      <h1>
        <span className='text-color--orange'>E</span>lin &amp; <span className='text-color--orange'>A</span>lexanders br&ouml;llop</h1>
    </header>
  );
}