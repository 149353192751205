import React from 'react'
import Header from './Header'
import Menu from './Menu'
import hall from './hall.jpg'

import './info.css'

export default () => (
  <main className='info'>
    <Header />
    <Menu />
    <div>
      <img src={hall} className='bg' />
      <div className='content'>
        <h2 className='content-title'>Bröllopet</h2>
        <p>Den 3:e augusti 2019 gifter vi oss och vi är så glada att Ni vill förgylla vår dag med Er närvaro!</p>
        <br />
        <h3 className='content-sub-title'>Vigseln</h3>
        <p>Vigseln sker klockan 14:00 i Ljusterö Kyrka, Ljusterö-Kulla församling. </p>
        <br />
        <h3 className='content-sub-title'>Bröllopsfesten</h3>
        <p>Efter vigseln transporteras samtliga gäster med båt till Strömkajen, Stockholm, varifrån det är en kort promenad till festlokalen på Kungliga Operan. 
        </p>
          <p>
          Brudskålen hålls på Guldterassen och därefter följer en sittande trerätters-middag, bröllopstårta, dans och firande i Guldfoajen.
          </p>
      </div>
    </div>
  </main>
)
