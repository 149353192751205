import React from 'react'
import Header from './Header'
import Menu from './Menu'
import bg from './hall.jpg'

import './home.css'

export default () => (
  <main className='info'>
    <Header />
    <Menu />
    <div>
      <img src={bg} className='bg' />
      <div className='content'>
        <h2 className='content-title'>Anmäl tal</h2>
        <p>
          Vill Ni hålla tal, spexa eller på annat sätt visa er uppskattning till brudparet?
      </p>
      <p>
        Anmäl intresse till våra kära toastmasters; Emelie Tirmén och Richard Ekstrand! 
      </p>
      <p>
        Observera att brudparet inte har tillgång till denna email-adress!
      </p>
      <p>
      <a href='mailto:toastmaster@stime.se'>toastmaster@stime.se</a>
      </p>
      </div>
    </div>
  </main>
)
